import React from 'react'
import styled from 'styled-components'


const Card = styled.div`
  width: 100%;
  max-width: 600px;
  border-radius: 10px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  padding: 7px;
  background: #fff;
  display: flex;
  flex-direction: column;
  gap: 10px;
  transition: all 0.3s ease-in-out;
  &:hover {
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  }
  @media (max-width: 768px) {
    width: 90%;
    padding: 12px;
  }
`;

const Top = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;
`;

const Image = styled.img`
  height: 40px;
  width: 40px;
  border-radius: 50%;
  background-color: #000;
`;

const Body = styled.div`
  display: flex;
  flex-direction: column;
`;

const Role = styled.div`
  font-size: 16px;
  font-weight: 600;
  color: #333;
`;

const Company = styled.div`
  font-size: 14px;
  font-weight: 500;
  color: #666;
`;

const Date = styled.div`
  font-size: 12px;
  font-weight: 400;
  color: #999;
`;

const Skills = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 6px;
`;

const Skill = styled.div`
  font-size: 12px;
  font-weight: 400;
  color: #666;
  background: #f0f0f0;
  padding: 4px 8px;
  border-radius: 8px;
`;

const ExperienceCard = ({ experience }) => {
  return (
    <Card>
      <Top>
        <Image src={experience.img || "https://i.ibb.co/gmKStsn/img1-yr-23-24.png"} alt="company logo" />
        <Body>
          <Role>{experience.role}</Role>
          <Company>{experience.company}</Company>
          <Date>{experience.date}</Date>
        </Body>
      </Top>
      <Skills>
        {experience.skills.map((skill, index) => (
          <Skill key={index}>{skill}</Skill>
        ))}
      </Skills>
    </Card>
  );
};

export default ExperienceCard;
