import { useState } from "react";
import styled from 'styled-components'
import ExperienceCard from '../Cards/ExperienceCard';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px 0;
  @media (max-width: 960px) {
    padding: 10px;
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background-color: #333;
  max-width: 800px;
  padding: 40px 20px;
  margin-bottom: 100px;
  margin-top: 50px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  @media (max-width: 960px) {
    padding: 20px 10px;
  }
`;

const Title = styled.div`
  font-size: 32px;
  text-align: center;
  font-weight: 600;
  color: ${({ theme }) => theme.white};
  margin-bottom: 10px;
`;

const Desc = styled.div`
  font-size: 1px;
  text-align: center;
  color: #666;
  margin-bottom: 20px;
`;

const UnderDevelopment = styled.div`
  font-size: 17px;
  text-align: center;
  color: #ff5722;
  margin-bottom: 5px;
`;

const FreelanceInfo = styled.div`
  font-size: 16px;
  text-align: center;
  color: ${({ theme }) => theme.text_secondary};
  margin-bottom: 30px;
`;

const TimelineSection = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
`;

const MoreButton = styled.a`
  margin-top: 30px;
  padding: 15px 25px;
  background: hsla(30, 100%, 50%, 1);
background: linear-gradient(225deg, hsla(30, 100%, 50%, 1) 0%, hsla(0, 100%, 50%, 1) 100%);
background: -moz-linear-gradient(225deg, hsla(30, 100%, 50%, 1) 0%, hsla(0, 100%, 50%, 1) 100%);
background: -webkit-linear-gradient(225deg, hsla(30, 100%, 50%, 1) 0%, hsla(0, 100%, 50%, 1) 100%);
-20px -20px 60px #6b3e26;
  color: #fff;
  text-align: center;
  font-size: 18px;
  font-weight: 500;
  border-radius: 8px;
  text-decoration: none;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #6e38c6;
  }
`;

const Experience = () => {
  const [experiences] = useState([
    {
      role: "Web and Digital Media Specialist",
      company: "Web Developer & Digital Marketing Freelancer with expertise in content creation, graphic design, and boosting online presence.",
      date: "Present",
      skills: ["Web Developer", "Designing Logos", "Multimedia Content", "Data Analysis", "UI/UX Design", "Flutter Mobile"],
    },
    {
      role: "Sales and Marketing",
      company: "Sales Representative specializing in both Real estate and Interior finishing materials for Housing and Building Projects",
      date: "Present",
      skills: ["Understanding Client Needs","Presentation Skills","Product Knowledge","Customer Relationship", "Technical Skills"],
    },
  ]);

  return (
    <Container id="experience">
      <Wrapper>
        <Title>Experience</Title>
        <UnderDevelopment>Section under development. Stay tuned for more updates!</UnderDevelopment>
        <FreelanceInfo>
          Currently working in <b>Sales</b> while also providing <b>Web development</b> and <b>Digital marketing services</b>..
        </FreelanceInfo>
        <TimelineSection>
          {experiences.map((experience, index) => (
            <ExperienceCard key={index} experience={experience} />
          ))}
        </TimelineSection>
        <MoreButton href="https://drive.google.com/file/d/1RTQYj8xHx9DR4-1wv5Wgx5_bAoA0PWbE/view?usp=sharing" target="_blank" rel="noopener noreferrer">View my Resume</MoreButton>
      </Wrapper>
    </Container>
  );
};

export default Experience;
