import React from "react";
import styled from "styled-components";

const Div = styled.div`
  @media (min-width: 768px) {
    display: none; /* Hide on screens wider than 768px */
  }
`;

const HeroBgAnimation = () => (
  <Div>
    <svg
      className="BgAnimation__svg"
      viewBox="0 0 1200 1200" // Adjust the viewBox to fit your desired size
      width="100%" // Use percentage for responsiveness
      height="100%" // Use percentage for responsiveness
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      {/* Pulsating circles in the center */}
      <circle cx="50%" cy="-5%" r="20%" fill="#b71c1c">
        {/* Adjust the position and size */}
        <animate
          attributeName="r"
          values="15%;34%;15%"
          dur="2s"
          repeatCount="indefinite"
        />
        <animate
          attributeName="opacity"
          values="1;0.5;1"
          dur="2s"
          repeatCount="indefinite"
        />
      </circle>
      <circle cx="50%" cy="-5%" r="20%" fill="#FFD700">
        {/* Adjust the position and size */}
        <animate
          attributeName="r"
          values="10%;30%;10%"
          dur="3s"
          repeatCount="indefinite"
        />
        <animate
          attributeName="opacity"
          values="1;0.5;1"
          dur="3s"
          repeatCount="indefinite"
        />
      </circle>
      <circle cx="50%" cy="-5%" r="20%" fill="#6a1b9a">
        {/* Adjust the position and size */}
        <animate
          attributeName="r"
          values="5%;28%;5%"
          dur="4s"
          repeatCount="indefinite"
        />
        <animate
          attributeName="opacity"
          values="1;0.5;1"
          dur="4s"
          repeatCount="indefinite"
        />
      </circle>
    </svg>
  </Div>
);

export default HeroBgAnimation;
