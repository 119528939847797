export const darkTheme = {
  bg: "#2E2E38", // Dark elegant background
  bgLight: "#393940", // Slightly lighter background
  primary: "#C19A6B", // Elegant gold accent color
  text_primary: "#E0E0E3", // Light text color
  text_secondary: "#A3A3A7", // Secondary text color
  card: "#3B3B45", // Dark card color
  card_light: "#46464F", // Slightly lighter card color
  button: "#D9534F", // Stylish red button color
  white: "#E0E0E3", // Replace white with light color
  black: "#2E2E38", // Replace black with dark color
  gold: "rgb(255, 215, 0)",
  paleyellow: "#EFFFAF",
};

export const lightTheme = {
  bg: "#F7F4E9", // Light elegant background
  bgLight: "#EFE8D9", // Slightly lighter background
  primary: "#D9534F", // Stylish red accent color
  text_primary: "#5C5C5E", // Dark text color
  text_secondary: "#7A7A7D", // Secondary text color
  card: "#F0EAD6", // Light card color
  button: "#C19A6B", // Elegant gold button color
  white: "#F7F4E9", // Replace white with light color
  black: "#5C5C5E", // Replace black with dark color
};
