import React, { useState } from "react";
import {
  Container,
  Wrapper,
  Title,
  Desc,
  ComingSoonButton,
} from "./ProjectsStyle";

const Projects = () => {
  return (
    <Container id="projects">
      <Wrapper>
        <Title>Projects</Title>
        <Desc>
          My projects are currently under development. Please check back soon
          for exciting updates!
        </Desc>
        <ComingSoonButton href="https://portfolio.seanong.com/" target="_blank" rel="noopener noreferrer">
          View Website Portfolio
        </ComingSoonButton>
      </Wrapper>
    </Container>
  );
};

export default Projects;
