import styled from "styled-components";
import _default from "../../themes/default";

export const Container = styled.div`
  background: linear-gradient(
    343.07deg,
    rgba(132, 59, 206, 0.06) 5.71%,
    rgba(132, 59, 206, 0) 64.83%
  );
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  z-index: 1;
  align-items: center;
  clip-path: polygon(0 0, 100% 0, 100% 100%, 100% 98%, 0 100%);
`;

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  max-width: 800px;
  padding: 50px 20px;
  gap: 20px;
  @media (max-width: 960px) {
    flex-direction: column;
  }
`;

export const Title = styled.div`
  font-size: 42px;
  text-align: center;
  font-weight: 600;
  color: ${({ theme }) => theme.text_primary};
  @media (max-width: 768px) {
    font-size: 32px;
  }
`;

export const Desc = styled.div`
  font-size: 18px;
  text-align: center;
  max-width: 600px;
  color: ${({ theme }) => theme.text_secondary};
  @media (max-width: 768px) {
    font-size: 16px;
  }
`;

export const ComingSoonButton = styled.a`
  margin-top: 30px;
  padding: 20px 40px;
  background: hsla(30, 100%, 50%, 1);
background: linear-gradient(225deg, hsla(30, 100%, 50%, 1) 0%, hsla(0, 100%, 50%, 1) 100%);
background: -moz-linear-gradient(225deg, hsla(30, 100%, 50%, 1) 0%, hsla(0, 100%, 50%, 1) 100%);
background: -webkit-linear-gradient(225deg, hsla(30, 100%, 50%, 1) 0%, hsla(0, 100%, 50%, 1) 100%);
-20px -20px 60px #6b3e26;
  color: #fff;
  text-align: center;
  font-size: 20px;
  font-weight: 500;
  border-radius: 8px;
  text-decoration: none;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #6e38c6;
  }
`;
