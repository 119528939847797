export const Bio = {
  name: "Sean Joshua Ong",
  roles: [
    "Web & Mobile Developer",
    "Digital Marketing",
    "Sales & Marketing Innovation",
    "Real Estate Services",
    "AI-Powered Solutions",
  ],
  description:
    "BSCS graduate specializing in Web Development and Digital Marketing. Skilled in AI solutions and Flutter, with growing expertise in Sales. Passionate about learning, innovating solutions, and delivering impactful results creatively.",
  github: "https://github.com/SeanODev",
  resume:
    "https://drive.google.com/file/d/1RTQYj8xHx9DR4-1wv5Wgx5_bAoA0PWbE/view?usp=sharing",
  linkedin: "https://www.linkedin.com/in/seanjoshuaong/",
  twitter: "https://x.com/SeanJoshuaOng",
  insta: "https://www.instagram.com/seanjoshuaong/",
  facebook: "https://www.facebook.com/SeanOng.SJO/",
  messenger: "https://m.me/SeanOng.SJO/",
  threads: "https://www.threads.net/@seanjoshuaong",
  whatsapp: "https://wa.me/639083803058",
  viber: "viber://chat?number=639083803058",
  services: "https://services.seanong.com/",
};

const baseUrl = "https://i.ibb.co";
const secondUrl = "https://res.cloudinary.com/odysean/image/upload";


export const skills = [
  {
    title: "Technical Skills",
    skills: [
      {
        name: "React Js",
        image: `${baseUrl}/LJJyKT4/reactJS.png`,
      },
      {
        name: "Flutter",
        image: `${baseUrl}/mFwR4Wr/flutter.png`,
      },
      {
        name: "Javascript",
        image: `${secondUrl}/v1721211455/js_qdaemp.png`,
      },
      {
        name: "Firebase",
        image: `${baseUrl}/jz5gzcd/firebase.png`,
      },
      {
        name: "Figma",
        image: `${baseUrl}/fXGjn1B/figma.png`,
      },
      {
        name: "Python",
        image: `${baseUrl}/sV2Xw5R/python.png`,
      },
      {
        name: "Wordpress",
        image: `${secondUrl}/v1721211456/wordpress_jzafup.png`,
      },
      {
        name: "HTML",
        image: `${secondUrl}/v1721211455/html_atgwxh.png`,
      },
      {
        name: "CSS",
        image: `${baseUrl}/7NFYQPr/css.png`,
      },
      {
        name: "Git",
        image: `${baseUrl}/gPDg8Fy/git.png`,
      },
      {
        name: "MySQL",
        image: `${baseUrl}/zRm7PVY/mysql.png`,
      },
      {
        name: "Dart",
        image: `${baseUrl}/JR2Wr4k/dart.png`,
      },
    ],
  },
  {
    title: "Digital Marketing",
    skills: [
      {
        name: "SEO",
        image: `${baseUrl}/Yttv5r9/seo.png`,
      },
      {
        name: "Content Creation",
        image: `${secondUrl}/v1721211456/contentcreation_mdkl9g.png`,
      },
      {
        name: "Analytics",
        image: `${baseUrl}/9ryfXnk/analytics.png`,
      },
      {
        name: "Multimedia",
        image: `${baseUrl}/P1zjpw6/multimedia.png`,
      },
      {
        name: "CRM",
        image: `${baseUrl}/Db5Nvck/crm.png`,
      },
      {
        name: "Trends and Insights",
        image: `${baseUrl}/dP51nL4/trend.png`,
      },
      {
        name: "Copywriting",
        image: `${baseUrl}/nMyhqHy/writing.png`,
      },
      {
        name: "Traditional Marketing",
        image: `${baseUrl}/7rDHYLV/traditionalmarketing.png`,
      },
      {
        name: "Strategic Planning",
        image: `${baseUrl}/SJWyLF0/planning.png`,
      },
    ],
  },
  {
    title: "Sales",
    skills: [
      {
        name: "Presentation",
        image: `${baseUrl}/09WKCNV/presentation.png`,
      },
      {
        name: "Networking",
        image: `${baseUrl}/BPXJcfp/network.png`,
      },
      {
        name: "Lead Generation",
        image: `${baseUrl}/rM4NvfR/lead.png`,
      },
      {
        name: "Remote Selling",
        image: `${baseUrl}/YLKXKkh/remotesell.png`,
      },
      {
        name: "Product Knowledge",
        image: `${baseUrl}/6chsy0f/product.png`,
      },
      {
        name: "Digital Sales",
        image: `${baseUrl}/Bw4LBp1/digital.png`,
      },
      {
        name: "Sales Automation",
        image: `${baseUrl}/7kk6pkZ/automation.png`,
      },
    ],
  },
  {
    title: "AI Powered Solutions",
    skills: [
      {
        name: "NLP",
        image: `${baseUrl}/SmFwNq6/nlp.png`,
      },
      {
        name: "AI Application",
        image: `${baseUrl}/HTkK6PV/aiapp.png`,
      },
      {
        name: "Generative AI",
        image: `${baseUrl}/x2cB2bf/generative.png`,
      },
      {
        name: "Deep learning",
        image: `${baseUrl}/nw6SsQ4/deeplearning.png`,
      },
      {
        name: "Tensorflow",
        image: `${baseUrl}/R0qV6WJ/tensorflow.png`,
      },
      {
        name: "Diffusion Model",
        image: `${baseUrl}/B2h4xp3/diffusion.png`,
      },
      {
        name: "Customer Service Automation",
        image: `${baseUrl}/qmg9gC1/aiautomation.png`,
      },
      {
        name: "AI Enthusiast",
        image: `${baseUrl}/2kyGSGT/aienthusiast.png`,
      },
    ],
  },
];

export const education = [
  {
    id: 0,
    img: `${baseUrl}/JsGfZ6h/ama1.png`,
    school: "AMA University",
    date: "2018 - 2021",
    desc: "BS in Computer Science specializing in web development. Skilled in designing, developing, and implementing web applications. Experienced in NLP and chatbot algorithms.",
    degree: "Bachelor's Degree in Computer Science",
  },
  {
    id: 1,
    img: `${baseUrl}/yWz3wZj/pcc1.png`,
    school: "Pasig Catholic College",
    date: "2016 - 2018",
    desc: "Completed the STEM strand, gaining expertise in STEM subjects and developing critical thinking, problem-solving, teamwork, and communication skills. Enhanced logical reasoning and data analysis through hands-on projects.",
    degree:
      "STEM(Science, Technology, Engineering, and Mathematics), Senior High",
  },
];
