import React from "react";
import styled from "styled-components";
import { Bio } from "../../data/constants";
import bgImage from "../../images/bg3.png";
import qrImage from "../../images/seanong_netlifyQR.png";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 80px 0;
  background: url(${bgImage}) no-repeat center center;
  background-size: cover;
`;

const Card = styled.div`
  background: hsla(30, 100%, 50%, 1);
  background: linear-gradient(
    225deg,
    hsla(30, 100%, 50%, 1) 0%,
    hsla(0, 100%, 50%, 1) 100%
  );
  background: -moz-linear-gradient(
    225deg,
    hsla(30, 100%, 50%, 1) 0%,
    hsla(0, 100%, 50%, 1) 100%
  );
  background: -webkit-linear-gradient(
    225deg,
    hsla(30, 100%, 50%, 1) 0%,
    hsla(0, 100%, 50%, 1) 100%
  );
  box-shadow: 20px 20px 60px #6b3e26, -20px -20px 60px #6b3e26;
  padding: 40px;
  border-radius: 15px;

  max-width: 500px;
  width: 100%;
  text-align: center;

  @media (max-width: 960px) {
    padding: 30px;
    max-width: 80%;
  }

  @media (max-width: 640px) {
    padding: 20px;
    max-width: 90%;
  }

  @media (max-width: 480px) {
    padding: 15px;
    max-width: 95%;
  }
`;

const Title = styled.h2`
  font-size: 36px;
  font-weight: 600;
  color: ${({ theme }) => theme.gold};
  margin-bottom: 20px;

  @media (max-width: 640px) {
    font-size: 28px;
  }

  @media (max-width: 480px) {
    font-size: 24px;
  }
`;

const ContactInfo = styled.div`
  margin-bottom: 20px;
`;

const InfoItem = styled.div`
  font-size: 18px;
  color: ${({ theme }) => theme.text_primary};
  margin: 10px 0;

  span {
    font-weight: bold;
    color: ${({ theme }) => theme.gold};
  }

  @media (max-width: 640px) {
    font-size: 16px;
  }

  @media (max-width: 480px) {
    font-size: 14px;
  }
`;

const SocialMediaIcons = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
`;

const SocialMediaIcon = styled.a`
  margin: 0 10px;
  width: 42px;
  height: 42px;
  display: inline-block;
  transition: transform 0.2s ease-in-out;
  &:hover {
    transform: scale(1.2); /* Enlarge on hover */
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;

const QRCodeContainer = styled.div`
  margin-top: 20px;
  img {
    width: 280px;
    height: 280px;
    border-radius: 10px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);

    @media (max-width: 480px) {
      width: 240px;
      height: 240px;
    }
  }
`;

const SkillsContainer = styled.div`
  margin-top: 15px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1px;
`;

const Skill = styled.div`
  background-color: ${({ theme }) => theme.skillBackground};
  color: ${({ theme }) => theme.gold};
  padding: 5px 10px;
  border-radius: 8px;
  font-size: 14px;

  @media (max-width: 480px) {
    font-size: 12px;
    padding: 3px 8px;
  }
`;

const Contact = () => {
  return (
    <Container id="contact">
      <Card>
        <Title>Contact</Title>
        <ContactInfo>
          <InfoItem>
            <span>Name:</span> Sean Joshua Ong
          </InfoItem>
          <InfoItem>
            <span>Email:</span> contact@seanong.com
          </InfoItem>
          <InfoItem>
            <span>Phone:</span> +63 908 380 3058
          </InfoItem>
          <InfoItem>
            <span>Website:</span> www.seanong.com
          </InfoItem>
        </ContactInfo>
        <SocialMediaIcons>
          <SocialMediaIcon href={Bio.messenger} target="_blank">
            <img
              src="https://res.cloudinary.com/odysean/image/upload/v1721211450/messenger_gtp1tc.png"
              alt="Messenger"
            />
          </SocialMediaIcon>
          <SocialMediaIcon href={Bio.threads} target="_blank">
            <img
              src="https://res.cloudinary.com/odysean/image/upload/v1721211450/threads_iabdq1.png"
              alt="Threads"
            />
          </SocialMediaIcon>
          <SocialMediaIcon href={Bio.linkedin} target="_blank">
            <img
              src="https://res.cloudinary.com/odysean/image/upload/v1721211449/linkedin1_wub5dr.png"
              alt="LinkedIn"
            />
          </SocialMediaIcon>
          <SocialMediaIcon href={Bio.whatsapp} target="_blank">
            <img
              src="https://res.cloudinary.com/odysean/image/upload/v1721211450/whatsapp_uqek5n.png"
              alt="Whatsapp"
            />
          </SocialMediaIcon>
          <SocialMediaIcon href={Bio.viber} target="_blank">
            <img
              src="https://res.cloudinary.com/odysean/image/upload/v1721211451/viber_fes7jv.png"
              alt="Viber"
            />
          </SocialMediaIcon>
        </SocialMediaIcons>
        <QRCodeContainer>
          <img src={qrImage} alt="QR Code" />
        </QRCodeContainer>
        <SkillsContainer>
          <Skill>Web Dev</Skill>
          <Skill>Digital Marketing</Skill>
          <Skill>Sales</Skill>
          <Skill>Generative AI</Skill>
        </SkillsContainer>
      </Card>
    </Container>
  );
};

export default Contact;
